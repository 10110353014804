@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@import "primeng/resources/themes/lara-light-blue/theme.css";

@import "primeng/resources/primeng.css";

@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// @import "../../../libs/core/components/layout/src/layout/layout.component.scss";


span,
body {
  font-size: 12px;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8a8a8a;
}

.ngx-datatable .datatable-body-cell-label {
  font-size: 12px;
}

.ant-form-item {
  margin-bottom: 12px !important;
  .ant-form-item-label {
    label {
      @apply text-xs font-semibold;
    }
  }
}

nz-modal-container {
  .ant-modal {
    max-height: calc(100vh - 200px) !important;
    display: flex;

    .ant-modal-content {
      display: flex;
      width: 100%;
      flex-direction: column;

      .ant-modal-body {
        overflow: auto;
      }
    }
    .ant-modal-footer {
      display: flex;
    }
  }
}

nz-tabset {
  height: 100%;
  .ant-tabs-content {
    height: 100%;
  }
}

// Table
ngx-datatable {
  datatable-header {
    min-height: 50px;

    datatable-header-cell {
      span {
        font-weight: 600 !important;
        font-size: 13px;
        color: #333333;
      }
    }
  }
  div.visible {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  datatable-body {
    overflow: auto;
  }

  datatable-footer {
    min-height: 50px;
    overflow: hidden !important;
  }
}

button {
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
}

nz-form-label {
  width: 200px !important;
}

.ant-form-vertical nz-form-label {
  width: 100% !important;
}

icom-libs-product-list formly-form formly-field formly-group {
  display: flex;
}

icom-libs-inventory-items-list formly-form formly-field formly-group {
  display: flex;
}

icom-libs-product-list formly-form formly-field formly-group {
  display: flex;
}
icom-libs-inventory-customer formly-form formly-field formly-group {
  display: flex;
}

icom-libs-campaign-transaction-list formly-form formly-field formly-group {
  display: flex;
}

// app-custom-form formly-form formly-field formly-group {
//   display: flex;
// }

.ant-modal-confirm-btns {
  display: flex;
}

.ant-upload-list-item-error {
  border: 1px solid #8a8a8a !important;
  color: #333333 !important;
}

.ant-statistic-content {
  font-size: 12px !important;
}

formly-validation-message {
  font-size: 12px;
}
.anticon {
  vertical-align: baseline !important;
}

.ant-pagination-item-link {
  display: block !important;
}

gridster {
  background: white !important;
}

nz-tabs-nav {
  margin: 0 !important;
}

.ant-collapse-borderless>.ant-collapse-item:last-child {
  border-bottom: 1px solid #d9d9d9 !important;
}

// icom-libs-admin-user-history-detail {
//   height: 100%;
// }

nz-tabset .ant-tabs-content {
  height: 100% !important;
}
nz-table-title-footer {
  position: absolute;
}

nz-tabset {
  .ant-tabs-tabpane {
    overflow: auto;
    height: 100%;
  }
  
}

nz-table {
  table:has(nz-embed-empty) {
    height: 100%;
  }
  overflow: hidden;
  nz-spin {
    height: 100%;
    .ant-spin-container {
      height: calc(100% - 50px) !important;
      .ant-table {
        height: 100%;
        .ant-table-container {
          height: 100%;
          overflow: hidden;
          .ant-table-body {
            overflow-x: scroll;
            overflow-x: hidden;
            height: calc(100% - 50px);
          }
        }
      }
    }
  }
}